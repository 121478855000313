import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import Header from "../../../components/Header/Header";
import SideNavbar from "../../../components/Navbar/SideNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import LeadGenForm from "../../../components/LeadGenBlock/LeadGenForm";

const LandingPage = () => {
    const params = useParams();
    const [pageMode, setPageMode] = useState("");
    const navigate = useNavigate();

    const backArrowClickHadler = () => {
        navigate('/blocks/lead-gen');
    }

    useEffect(() => {
        const currentPath = window.location.pathname;
        if (currentPath.includes("create")) {
            setPageMode("CREATE");
        } else {
            if (params.id) {
                setPageMode("EDIT");
            }
        }
    }, [params, window]);

    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <div className="Subheader bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
                            <div className="flex items-center w-full">
                                <ArrowLeftSLineIcon
                                    onClick={backArrowClickHadler}
                                    size={24}
                                    className="mr-4 cursor-pointer"
                                />
                                <p className="text-xl font-bold text-[#43425D] flex items-center">
                                    {pageMode === "CREATE" ? "Create" : "Update"}
                                </p>
                            </div>
                        </div>
                        <LeadGenForm pageMode={pageMode} id={params.id} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default LandingPage;