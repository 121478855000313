import { useEffect, useState } from "react";
import { getBrandsService, getCategoriesService } from "../../../core/services/adsenseServices";
import { uploadImageService } from "../../../core/services/utilService";
import toast from "react-hot-toast";
import { createLandingPage, getLandingPageById, updateLandingPage } from "../../../core/services/cmsreadService";
import { PAGE_MODES } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";

interface Brand {
    id: string;
    name: string;
    link: string;
    brandLogo: string;
}

interface Category {
    id: string;
    name: string;
}

interface CategoryV2 {
    categoryCode: string;
    categoryName: string;
}

interface Offer {
    id?: string;
    pageName: string;
    offerId?: string;
    brandName?: string;
    brandId: string;
    categoryName?: string;
    categoryId?: string;
    l1Category?: Category;
    l2Category?: Category;
    l3Category?: Category;
    updatedAt?: Date;
    createdAt?: Date;
    banner?: {
        pwa?: string;
        desktop?: string;
    };
    url?: string;
}


const LeadGenForm = (props: any) => {

    const navigate = useNavigate();
    const [loader, setLoader] = useState<boolean>(false);
    const [brands, setBrands] = useState<Brand[]>([]);
    const [categories, setCategories] = useState<CategoryV2[]>([]);
    const [prevFileM, setPrevFileM] = useState("");
    const [prevFileD, setPrevFileD] = useState("");
    const [offer, setOffer] = useState<Offer>({
        id: "",
        pageName: "",
        brandName: "",
        brandId: "",
        offerId: "",
        categoryName: "",
        categoryId: "",
        l1Category: {
            id: "",
            name: ""
        },
        l2Category: {
            id: "",
            name: ""
        },
        l3Category: {
            id: "",
            name: ""
        },
        banner: {
            pwa: "",
            desktop: ""
        },
        url: ""
    });
    const [mobileImageFile, setMobileImageFile] = useState<File | null>(null);
    const [desktopImageFile, setDesktopImageFile] = useState<File | null>(null);
    const [generatedUrl, setGeneratedUrl] = useState<string>("");

    useEffect(() => {
        getBrandsAndPopulate();
        getCategoriesAndPopulate();
    }, []);

    useEffect(() => {
        if (props.id && props.pageMode === PAGE_MODES.EDIT) {
            // Call API and populate
            getLandingPageById(props.id)
                .then(({ data }) => {
                    if (data?.data) {
                        const banner = data.data.banner;
                        setPrevFileM(banner?.pwa || "");
                        setPrevFileD(banner?.desktop || "");
                        setOffer(data.data);
                        setGeneratedUrl(data.data.url)
                    } else {
                        console.warn("Data is undefined or null");
                    }
                })
                .catch((err) => {
                    console.error("Error fetching landing page data:", err);
                });
        }
    }, [props]);

    // Fetch and populate brands
    async function getBrandsAndPopulate(): Promise<void> {
        setLoader(true);
        try {
            const { data } = await getBrandsService();
            if (data && data.brands) {
                const sortedBrands = data.brands.sort((a: any, b: any) =>
                    a.name.localeCompare(b.name)
                );
                setBrands(sortedBrands);
            }
        } catch (err) {
            console.error("Error fetching brands:", err);
        } finally {
            setLoader(false);
        }
    }

    // Fetch categories and populate
    async function getCategoriesAndPopulate(): Promise<void> {
        setLoader(true);
        try {
            const { data } = await getCategoriesService();
            if (data.categoryList && data.categoryList) {
                const sortedCategories = data.categoryList.sort((a: any, b: any) =>
                    a.categoryName.localeCompare(b.categoryName)
                );
                setCategories(sortedCategories);
            }
        } catch (err) {
            console.error("Error fetching categories:", err);
        } finally {
            setLoader(false);
        }
    }

    // Utility API to upload file to S3
    async function uploadFile(file: File): Promise<string> {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("bucketName", "common");
        const { data } = await uploadImageService(formData);
        return data.data.s3ImageUrl;
    }

    // Handle input changes
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setOffer((prev) => ({ ...prev, [name]: value }));
    };

    // Add new validation function
    const validateImageDimensions = (file: File, expectedWidth: number, expectedHeight: number): Promise<boolean> => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                URL.revokeObjectURL(img.src);
                resolve(img.width === expectedWidth && img.height === expectedHeight);
            };
        });
    };

    // Update handleFileChange to include validation
    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>, type: "mobile" | "desktop") => {
        const file = e.target.files?.[0] || null;
        if (!file) return;

        const isValid = await validateImageDimensions(
            file,
            type === "mobile" ? 375 : 1176,
            type === "mobile" ? 179 : 599
        );

        if (!isValid) {
            toast.error(`Please upload ${type === "mobile" ? "375x179" : "1176x599"} pixel image`);
            e.target.value = '';
            return;
        }

        if (type === "mobile") {
            setMobileImageFile(file);
        } else {
            setDesktopImageFile(file);
        }
    };

    function navigateToUpdateLandingPageHandler(id: string) {
        navigate(`/blocks/lead-gen/${id}`)
    }

    // Helper function to construct base URL
    const defineBaseUrl = () => {
        return process.env.REACT_APP_ENVIRONMENT === "PRODUCTION" ? 'https://www.moglix.com/' : 'https://qam.moglilabs.com/';
    };

    // Function to construct the offer URL
    const constructOfferUrl = (baseUrl: string, offerData: Offer, category: any) => {
        let cookedUrl;
        if (offerData.brandName) {
            cookedUrl = `${baseUrl}bulk-enquiry/${convertToSlug(offerData.brandName)}/`;
        }

        if (category) {
            cookedUrl += convertToSlug(`${category.categoryName}`);
        }

        cookedUrl += `?brandId=${offerData.brandId}&offerId=${offerData.offerId || ''}`;

        if (category) {
            cookedUrl += `&categoryId=${category.categoryCode}`;
        }

        return cookedUrl;
    };

    function convertToSlug(inputString: string) {
        if (typeof inputString !== "string") {
            throw new TypeError("Input must be a string");
        }
        return inputString.toLowerCase().replace(/\s+/g, '-');
    }

    // Function to save a new offer
    const saveOffer = async () => {
        try {
            if (!offer.pageName) {
                toast.error("Page name field is missing.");
                return
            }
            
            setLoader(true);

            // Step 1: Upload image files (if any) and get their URLs
            const mobileImageUrl = mobileImageFile ? await uploadFile(mobileImageFile) : "";
            const desktopImageUrl = desktopImageFile ? await uploadFile(desktopImageFile) : "";

            // Step 2: Validate image URLs
            if (!mobileImageUrl && !desktopImageUrl) {
                toast.error("Both mobile and desktop image URLs are missing.");
                return
            }

            // Step 3: Construct offer data object
            const offerData = {
                ...offer,
                banner: {
                    pwa: mobileImageUrl,
                    desktop: desktopImageUrl,
                },
            };

            // Add brand name to the offer data
            const brand = brands.find((b) => b.id === offerData.brandId)
            if (!brand) {
                toast.error("Brand field is required!")
                return
            }

            offerData.brandName = brand.name
            offerData.brandId = brand.id

            // Add category details to the offer data (if available)
            const category = categories.find((c) => c.categoryCode === offerData.categoryId);
            if (category) {
                offerData.categoryId = category.categoryCode;
                offerData.categoryName = category.categoryName;
            }

            // Step 4: Set the initial base URL
            const baseUrl = defineBaseUrl();
            offerData.url = baseUrl;

            // Step 5: Create a new landing page
            delete offerData.id; // Ensure the ID is removed for new creation
            const createLandingPageResp = await createLandingPage(offerData);
            const data = createLandingPageResp.data.data;

            offerData.offerId = data.offerId
            offerData.id = data.id

            // Step 6: Construct the final URL with the landing page ID
            const cookedUrl = constructOfferUrl(baseUrl, { ...offerData }, category);

            // Assign the constructed URL to offer data
            offerData.url = cookedUrl;

            // Step 7: Update the landing page with the final URL
            await updateLandingPage(data.id, offerData);

            setGeneratedUrl(cookedUrl || ""); // Save the generated URL for display

            // Step 8: Show success toast notification
            toast.success("Created successfully");
        } catch (err) {
            console.error("Error saving offer:", err);
            alert("Failed to save the offer. Please try again.");
        } finally {
            setLoader(false);
        }
    };

    // Function to update an existing offer
    const updateOffer = async () => {
        try {
            setLoader(true);

            // Step 1: Upload image files (if any) and get their URLs
            let mobileImageUrl = mobileImageFile ? await uploadFile(mobileImageFile) : "";
            let desktopImageUrl = desktopImageFile ? await uploadFile(desktopImageFile) : "";

            // Handle cases where existing images are retained
            if (offer.banner?.pwa && !mobileImageUrl) {
                mobileImageUrl = offer.banner.pwa;
            }
            if (offer.banner?.desktop && !desktopImageUrl) {
                desktopImageUrl = offer.banner.desktop;
            }

            // Step 2: Validate image URLs
            if (!mobileImageUrl && !desktopImageUrl) {
                throw new Error("Both mobile and desktop image URLs are missing.");
            }

            // Step 3: Construct offer data object
            const offerData = {
                ...offer,
                banner: {
                    pwa: mobileImageUrl,
                    desktop: desktopImageUrl,
                },
            };

            // Add brand name to the offer data
            const brand = brands.find((b) => b.id === offerData.brandId)

            if (!brand) {
                toast.error("Brand field is required!")
                return
            }


            offerData.brandName = brand.name
            offerData.brandId = brand.id

            // Add category details to the offer data (if available)
            const category = categories.find((c) => c.categoryCode === offerData.categoryId);
            if (category) {
                offerData.categoryId = category.categoryCode;
                offerData.categoryName = category.categoryName;
            }

            // Step 4: Set the base URL
            const baseUrl = defineBaseUrl();

            // Step 5: Construct the final URL with existing offer data
            const cookedUrl = constructOfferUrl(baseUrl, offerData, category);
            offerData.url = cookedUrl;

            // Step 6: Update the existing landing page with new data
            const updateLandingPageResp = await updateLandingPage(offerData.id || "", offerData);

            toast.success("Updated successfully");

            setGeneratedUrl(cookedUrl || ""); // Save the generated URL for display

            // Step 7: Navigate to updated landing page (if needed)
            navigateToUpdateLandingPageHandler(updateLandingPageResp?.data?.data?.id);
        } catch (err) {
            console.error("Error updating offer:", err);
            alert("Failed to update the offer. Please try again.");
        } finally {
            setLoader(false);
        }
    };


    function copyToClipboard() {
        navigator.clipboard.writeText(generatedUrl);
        toast.success("Copied successfully!")
    }

    const downloadFile = (fileUrl: string, fileName: string) => {
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    return (
        <div className="mx-5 mt-5 mb-2.5">
            <div className="bg-white p-5 rounded border border-[#EAEAEA]">
                <div className="flex items-center w-full mb-7 last:mb-0">
                    <p className="text-xs font-semibold text-[#303030] w-48">Page Name* :</p>
                    <div className="w-2/5">
                        <input
                            type="text"
                            name="pageName"
                            placeholder="Enter Page Name"
                            className="w-full rounded-sm border border-gray-300 outline-none text-xs px-2.5 py-1.5 text-[#303030]"
                            value={offer.pageName}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="flex items-center w-full mb-7 last:mb-0">
                    <p className="text-xs font-semibold text-[#303030] w-48">Brand Name* :</p>
                    <div className="w-2/5">
                        <select
                            name="brandId"
                            className="w-full rounded-sm border border-gray-300 text-xs px-2.5 py-1.5 text-[#303030]"
                            value={offer.brandId}
                            onChange={handleInputChange}
                        >
                            <option value="">Select</option>
                            {brands.map((b) => (
                                <option key={b.id} value={b.id}>
                                    {b.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="flex items-center w-full mb-7 last:mb-0">
                    <p className="text-xs font-semibold text-[#303030] w-48">Category Name :</p>
                    <div className="w-2/5">
                        <select
                            name="categoryId"
                            className="w-full rounded-sm border border-gray-300 text-xs px-2.5 py-1.5 text-[#303030]"
                            value={offer.categoryId}
                            onChange={handleInputChange}
                        >
                            <option value="">Select</option>
                            {categories.map((b) => (
                                <option key={b.categoryCode} value={b.categoryCode}>
                                    {b.categoryName}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="flex items-center w-full mb-7 last:mb-0">
                    <p className="text-xs font-semibold text-[#303030] w-48">Mobile Image* :</p>
                    <div className="w-2/5 flex flex-col gap-1">
                        <input
                            accept=".jpg,.png,.jpeg"
                            type="file"
                            className="w-full rounded-sm border border-gray-300 text-xs px-2.5 py-1.5 text-[#303030]"
                            onChange={(e) => handleFileChange(e, "mobile")}
                        />
                        <span className="text-xs text-gray-500">Required size: 375 x 179 pixels</span>
                        {prevFileM && (
                            <button
                                className="text-xs text-blue-500 underline"
                                onClick={() => downloadFile(prevFileM, "mobile-image")}
                            >
                                Download
                            </button>
                        )}
                    </div>
                </div>
                <div className="flex items-center w-full mb-7 last:mb-0">
                    <p className="text-xs font-semibold text-[#303030] w-48">Desktop Image* :</p>
                    <div className="w-2/5 flex flex-col gap-1">
                        <input
                            accept=".jpg,.png,.jpeg"
                            type="file"
                            className="w-full rounded-sm border border-gray-300 text-xs px-2.5 py-1.5 text-[#303030]"
                            onChange={(e) => handleFileChange(e, "desktop")}
                        />
                        <span className="text-xs text-gray-500">Required size: 1176 x 599 pixels</span>
                        {prevFileD && (
                            <button
                                className="text-xs text-blue-500 underline"
                                onClick={() => downloadFile(prevFileD, "desktop-image")}
                            >
                                Download
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <button
                    type="button"
                    className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded"
                    onClick={props.pageMode === PAGE_MODES.EDIT ? updateOffer : saveOffer}
                    disabled={loader}
                >
                    {loader ? "Saving..." : "Save Page"}
                </button>
            </div>
            {generatedUrl && (
                <div className="bg-white p-5 rounded border border-[#EAEAEA] mt-5">
                    <p className="text-base font-bold mb-5 text-[#303030]">Generated Url</p>
                    <div className="flex items-center mb-[30px]">
                        <input
                            type="url"
                            className="w-1/2 rounded-sm border border-gray-300 text-xs px-2.5 py-1.5 text-[#303030]"
                            value={generatedUrl}
                            readOnly
                        />
                        <button
                            type="button"
                            className="h-[30px] ml-5 px-4 text-xs text-white border font-medium border-[#3B86FF] bg-[#3B86FF] rounded"
                            onClick={copyToClipboard}
                        >
                            Copy
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
export default LeadGenForm;