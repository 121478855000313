import React, { useState } from 'react';
import axios from 'axios';
import { toast } from "react-hot-toast";
import SideNavbar from '../../components/Navbar/SideNavbar';
import Header from '../../components/Header/Header';

const UrlShortener: React.FC = () => {
    const [url, setUrl] = useState<string>('');
    const [type, setType] = useState<'permanent' | 'temporary'>('permanent');
    const [shortenedUrl, setShortenedUrl] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!url) {
            toast.error('Please enter a URL');
            return;
        }

        try {
            setLoading(true);
            // Hardcoded production URI, as ingress redirection is only handled in production.
            const response = await axios.post('https://api-gt.moglix.com/api/common/url/shorten', {
                url,
                type
            });

            if (response.data?.data?.shortUrl) {
                setShortenedUrl(response.data.data.absoluteUrl);
                toast.success('URL shortened successfully!');
            } else {
                toast.error('Failed to shorten URL');
            }
        } catch (error) {
            console.error('Error shortening URL:', error);
            toast.error('Error shortening URL');
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shortenedUrl)
            .then(() => toast.success('Copied to clipboard!'))
            .catch(() => toast.error('Failed to copy'));
    };

    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <div className="p-6 max-w-2xl mx-auto">
                            <h1 className="text-2xl font-bold mb-6">URL Shortener</h1>

                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Enter Long URL
                                    </label>
                                    <input
                                        type="url"
                                        value={url}
                                        onChange={(e) => setUrl(e.target.value)}
                                        className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="https://example.com"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        URL Type
                                    </label>
                                    <select
                                        value={type}
                                        onChange={(e) => setType(e.target.value as 'permanent' | 'temporary')}
                                        className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                    >
                                        <option value="permanent">Permanent (No Expiry)</option>
                                        <option value="temporary">Temporary (30 Days Expiry)</option>
                                    </select>
                                </div>

                                <button
                                    type="submit"
                                    disabled={loading}
                                    className={`w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${loading ? 'opacity-50 cursor-not-allowed' : ''
                                        }`}
                                >
                                    {loading ? 'Shortening...' : 'Shorten URL'}
                                </button>
                            </form>

                            {shortenedUrl && (
                                <div className="mt-6 p-4 bg-gray-50 rounded-md">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Shortened URL
                                    </label>
                                    <div className="flex gap-2">
                                        <textarea
                                            readOnly
                                            value={shortenedUrl}
                                            className="w-full p-2 border border-gray-300 rounded-md bg-white"
                                            rows={2}
                                        />
                                        <button
                                            onClick={copyToClipboard}
                                            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                        >
                                            Copy
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UrlShortener;